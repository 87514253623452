<template>
<g-card page-title="Systems Sync" page-icon="mdi-sync">
  <page-overlay :overlay="isOverlay"/>
  <v-row>
    <v-col :cols="cards.cols" :md="cards.md" :sm="cards.sm" :xs="cards.xs" v-for="(item, key) in cards.items" :key="key">
      <v-card>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">{{item.title}}</v-card-title>
            <v-card-subtitle>{{item.subTitle}}</v-card-subtitle>
            <v-card-actions>
              <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  @click="dynamicMethodHandler(item.handlerName)"
              >
                {{item.buttonText }}
              </v-btn>
            </v-card-actions>
          </div>
          <v-icon :color="item.iconColor" class="dashboard-widget-icon mr-2">{{item.icon}}</v-icon>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <confirm-popup
      :show="showConfirm"
      confirm-event="onConfirmItem"
      @closePopupHandler="showConfirm = false"
      @onConfirmItem="confirmEvent(currentSelectedItem, true)"
  >
    <div>
      <!-- eslint-disable-next-line  -->
      <span>{{ confirmTxt }}</span>
    </div>
  </confirm-popup>
</g-card>
</template>

<script>
import SystemsSync from "../services/SystemsSync";
import GCard from "../components/GCard";
import PageOverlay from "../components/PageOverlay";
import ConfirmPopup from "../components/popups/ConfirmPopup";
export default {
  name: "SystemSync",
  components: {
    ConfirmPopup,
    PageOverlay,
    GCard
  },
  data() {
    return{
      isOverlay: false,
      showConfirm: false,
      confirmTxt: "",
      confirmEvent: "",
      currentSelectedItem: null,
      cards: {
        cols: 12,
        md: 3,
        sm: 12,
        xs: 12,
        items: [
          {
            title: 'New Client',
            subTitle: 'Sync new client',
            handlerName: 'newClientSyncHandler',
            buttonText: 'SYNC Client',
            icon: 'mdi-sync-alert',
            iconColor: '#5253a2'
          },
          {
            title: 'Payment Sync',
            subTitle: 'Adjust payment',
            handlerName: 'paymentSyncHandler',
            buttonText: 'SYNC Payment',
            icon: 'mdi-flash',
            iconColor: '#5253a2'
          },
          {
            title: 'Status Sync',
            subTitle: 'Orders empty status sync',
            handlerName: 'statusSyncHandler',
            buttonText: 'SYNC Empty Status',
            icon: 'mdi-set-center',
            iconColor: '#5253a2'
          }
        ]
      }
    }
  },
  methods: {
    dynamicMethodHandler(nameOfTheHandler) {
      if(nameOfTheHandler) {
        this[nameOfTheHandler](null, false);
      } else {
        console.warn(`${nameOfTheHandler} is not yet defined!`)
      }
    },
    newClientSyncHandler(item, confirm=false) {
      this.confirmEvent = this["newClientSyncHandler"];
      this.confirmTxt = "Are sure to resync new client? Sync will apply on client which have less than 4 orders.";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      this.isOverlay = true;
      SystemsSync.sync()
          .then((response) => {
            this.setSnackBar({text: response.data.message, type:"info"})
          })
          .catch((error) => {
            let message = "Oops!Something went wrong."
            if(error.response && error.response.data.message){
              message = error.response.data.message;
            }
            this.setSnackBar({text: message, type:"error"})
          })
          .finally(() => {
            this.isOverlay = false;
          })
    },
    paymentSyncHandler(item, confirm=false) {
      this.confirmEvent = this["paymentSyncHandler"];
      this.confirmTxt = "Are sure to resync payment? Sync will apply on which orders are not paid but invoice paid.";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      this.isOverlay = true;
      SystemsSync.syncPayment()
          .then((response) => {
            this.setSnackBar({text: response.data.message, type:"info"})
          })
          .catch((error) => {
            let message = "Oops!Something went wrong."
            if(error.response && error.response.data.message){
              message = error.response.data.message;
            }
            this.setSnackBar({text: message, type:"error"})
          })
          .finally(() => {
            this.isOverlay = false;
          })
    },
    statusSyncHandler(item, confirm=false) {
      this.confirmEvent = this["statusSyncHandler"];
      this.confirmTxt = "Are sure to sync order status? Sync will apply on which order status NULL and not deleted.";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      this.isOverlay = true;
      SystemsSync.orderStatus()
          .then((response) => {
            this.setSnackBar({text: response.data.message, type:"info"})
          })
          .catch((error) => {
            let message = "Oops!Something went wrong."
            if(error.response && error.response.data.message){
              message = error.response.data.message;
            }
            this.setSnackBar({text: message, type:"error"})
          })
          .finally(() => {
            this.isOverlay = false;
          })
    },
    setSnackBar({text, type}){
      this.$store.commit("SET_SNACKBAR", { text, type });
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-widget-icon {
  font-size: 70px !important;
}
</style>