import Api from "./Api";

const sync = (payload) => {
    const path = "/systems/sync/new-client";
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const syncPayment = (payload) => {
    const path = "/systems/sync/payment-sync";
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const orderStatus = (payload) => {
    const path = "/systems/sync/order-status";
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

export default {
    sync,
    syncPayment,
    orderStatus
};
